import logo from "./images/logo.png";
import swieta from "./images/swieta.png";
import autobus from "./images/autobus.jpg";
import litwa from "./images/litwa.png";
import litwa2 from "./images/litwa2.png";
import jarmark from "./images/jarmark.png"

export const news = [
    {
        id: 'wyjazd-na-litwe-2',
        title: 'Zapraszamy na wycieczkę na Litwę',
        firstSentence: 'Zapraszamy na wycieczkę Kowno – Kiedany – Szawle – Wilno...',
        date: '23-03-2025',
        photo: litwa2,
    },
    {
        id: 'wyjazd-na-jarmark',
        title: 'Wyjazd na Jarmark Kaziukowy',
        firstSentence: 'Zapraszamy na wycieczkę Wilno – Jarmark Kaziukowy...',
        date: '17-01-2025',
        photo: jarmark,
    },
    {
        id: 'zyczenia-swiateczne',
        title: 'Życzenia bożonarodzeniowe',
        firstSentence: 'Z okazji Świąt Bożego Narodzenia oraz Nowego Roku życzymy naszym Klientom...',
        date: '23-12-2024',
        photo: swieta,
    },
    {
        id: 'nowy-autobus',
        title: 'Nowy autobus na trasie Mońki - Białystok!',
        firstSentence: 'Z radością informujemy, że od teraz na trasie Mońki - Białystok będzie...',
        date: '27-10-2024',
        photo: autobus,
    },
    {
        id: 'przypomnienie-bilety',
        title: 'Biuro czynne w sobotę – legitymacje i bilety',
        firstSentence: 'Przypominamy, że legitymacje szkolne tracą ważność 30 września...',
        date: '27-09-2024',
        photo: logo,
    },
    {
        id: 'wyjazd-na-litwe',
        title: 'Zapraszamy na wycieczkę na Litwę',
        firstSentence: 'Zapraszamy na wycieczkę Kowno – Kiedany – Szydłowo – Szawle – Wilno...',
        date: '02-09-2024',
        photo: litwa,
    },
];